import ALL_LOCATIONS from '@/graphql/locations/query/getAllLocationsAutocomplete.gql'

const locations = ref<{label: string; value: string}[]>([])
const destinations = ref<{label: string; value: string}[]>([])
const locations2 = ref<{label: string; value: string}[]>([])
const key1 = ref(0)
const key2 = ref(0)
const key3 = ref(0)
const firstTime = ref(true)
const firstTime2 = ref(true)
const firstTime3 = ref(true)

export async function locationsAutocomplete(params: any) {
  const apolloQuery = ALL_LOCATIONS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 500,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchLocationsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await locationsAutocomplete({ search: queryString })
    cb(results?.getAllLocations?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchLocationsCb')
  }
}

export function getSearchLocations(entityLocation: any, entityLocations: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchLocations('', entityLocation, entityLocations)
  }

  return locations?.value ?? []
}

export function getSearchDestinations(entityLocation: any, entityLocations: any) {
  if (firstTime2.value === true) {
    firstTime2.value = false
    searchDestinations('', entityLocation, entityLocations)
  }

  return destinations?.value ?? []
}

export function getSearchLocations2(entityLocation: any, entityLocations: any) {
  if (firstTime3.value === true) {
    firstTime3.value = false
    searchLocations2('', entityLocation, entityLocations)
  }

  return locations2?.value ?? []
}

function addRemoteEntityToArray(remoteArray: any, entityLocation: any, entityLocations: any, refresh = false) {
  const arrayToAdd: { label: any; value: any }[] = []
  entityLocations?.forEach((j: any) => {
    if (!remoteArray.find((k: any) => k.value === j.uuid)) {
      arrayToAdd.push({
        label: j.name,
        value: j.uuid
      })
    }
  })
  if (entityLocation && !remoteArray.find((k: any) => k.value === entityLocation.uuid)) {
    arrayToAdd.push({
      label: entityLocation.name,
      value: entityLocation.uuid
    })
  }
  if (refresh) {
    key1.value = 0
    key2.value = 0
    key3.value = 0
  }
  if (key1.value === 0) {
    key1.value = setCustomKeyVariable()
  }
  if (key2.value === 0) {
    key2.value = setCustomKeyVariable()
  }
  if (key3.value === 0) {
    key3.value = setCustomKeyVariable()
  }
  return [...remoteArray, ...arrayToAdd]
}

export async function searchLocations(queryString: any, entityLocation: any, entityLocations: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await locationsAutocomplete({ search: queryString?.length >= 3 ? queryString : '' })
    const remoteLocations = results?.getAllLocations?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []

    locations.value = addRemoteEntityToArray(remoteLocations, entityLocation, entityLocations, refresh)
    firstTime.value = false

    if (entityLocation) {
      getSearchLocations(entityLocation, entityLocations)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchLocations')
  }
}

export async function searchDestinations(queryString: any, entityLocation: any, entityLocations: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await locationsAutocomplete({ search: queryString?.length >= 3 ? queryString : '' })
    const remoteDestinations = results?.getAllLocations?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []

    destinations.value = addRemoteEntityToArray(remoteDestinations, entityLocation, entityLocations, refresh)
    firstTime2.value = false

    if (entityLocation) {
      getSearchDestinations(entityLocation, entityLocations)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchDestinations')
  }
}

export async function searchLocations2(queryString: any, entityLocation: any, entityLocations: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await locationsAutocomplete({ search: queryString?.length >= 3 ? queryString : '' })
    const remoteLocations = results?.getAllLocations?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []

    locations2.value = addRemoteEntityToArray(remoteLocations, entityLocation, entityLocations, refresh)  
    firstTime3.value = false

    if (entityLocation) {
      getSearchLocations2(entityLocation, entityLocations)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchLocations2')
  }
}

export function getLocationKey() {
  return key1.value
}

export function getDestinationsKey() {
  return key2.value
}

export function getLocations2Key() {
  return key3.value
}

export function initialLocations() {
  key1.value = 0
  key2.value = 0
  key3.value = 0
  locations.value = []
  destinations.value = []
  locations2.value = []
  firstTime.value = true
  firstTime2.value = true
  firstTime3.value = true
}